import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Import Firestore instance from your firebase configuration
import { useUserLevel, AdminLevel } from '../../admin';

interface User {
  id: string;
  name: string;
  email: string;
  groupId: string | null;
}

interface Group {
  id: string;
  groupId: string;
  recoveryPlan: string;
  Admin: string; // Admin is now a single email string
  Members: string[];
  password?: string;
}

interface ModifyGroupProps {
  onClose: () => void;
  groupId: string | null | undefined;
}

const ModifyGroup: React.FC<ModifyGroupProps> = ({ onClose, groupId }) => {
  const { currentUserLevel } = useUserLevel();
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [groupName, setGroupName] = useState('');
  const [recoveryPlan, setRecoveryPlan] = useState('');
  const [groupPassword, setGroupPassword] = useState('');
  const [recoveryPlans, setRecoveryPlans] = useState<string[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [filteredMembers, setFilteredMembers] = useState<User[]>([]);
  const [adminUser, setAdminUser] = useState<User | null>(null); // Admin user state
  const [groups, setGroups] = useState<Group[]>([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      const usersData: User[] = usersSnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name,
        email: doc.data().email,
        groupId: doc.data().groupId || null,
      }));
      setUsers(usersData);
    };

    const fetchRecoveryPlans = async () => {
      const recoveryPlansCollection = collection(db, 'recoveryPlans');
      const recoveryPlansSnapshot = await getDocs(recoveryPlansCollection);
      const plans = recoveryPlansSnapshot.docs.map(doc => doc.id);
      setRecoveryPlans(plans);
    };

    const fetchGroups = async () => {
      const groupsCollection = collection(db, 'groups');
      const groupsSnapshot = await getDocs(groupsCollection);
      const groupsData: Group[] = groupsSnapshot.docs.map(doc => ({
        id: doc.id,
        groupId: doc.data().groupId,
        recoveryPlan: doc.data().recoveryPlan,
        Admin: doc.data().Admin, // Now admin is a single email
        Members: doc.data().Members || [],
        password: doc.data().password || '',
      }));
      setGroups(groupsData);

      // Automatically set the selected group based on the groupId prop
      const selected = groupsData.find(group => group.groupId === groupId);
      if (selected) {
        setSelectedGroup(selected);
      }
    };

    fetchUsers();
    fetchRecoveryPlans();
    fetchGroups();
  }, [groupId]); // Fetch data and set the selected group when groupId changes

  useEffect(() => {
    if (selectedGroup) {
      setGroupName(selectedGroup.groupId);
      setRecoveryPlan(selectedGroup.recoveryPlan);
      setGroupPassword(selectedGroup.password || '');

      const admin = users.find(user => user.email === selectedGroup.Admin);
      setAdminUser(admin || null); // Set the admin user state

      const memberIds = new Set(users.filter(user => selectedGroup.Members.includes(user.email)).map(user => user.id));

      setFilteredMembers(users.filter(user => memberIds.has(user.id)));
    }
  }, [selectedGroup, users]);

  const handleDeleteMember = async (memberEmail: string) => {
    if (!selectedGroup) return;

    // Remove member from the group's member list
    const updatedMembers = selectedGroup.Members.filter(member => member !== memberEmail);
    
    const updatedGroup = {
      ...selectedGroup,
      Members: updatedMembers,
    };

    // Update the group in Firestore
    const groupDoc = doc(db, 'groups', selectedGroup.id);
    await updateDoc(groupDoc, updatedGroup);

    // Update the user in Firestore to remove the groupId
    const member = users.find(user => user.email === memberEmail);
    if (member) {
      const userDoc = doc(db, 'users', member.id);
      await updateDoc(userDoc, {
        groupId: null,
        recoveryPlan: null,
      });
    }

    // Refresh the UI
    setSelectedGroup(updatedGroup);
  };

  const handleUpdateGroup = async () => {

    if (!selectedGroup) return;

    const updatedGroup = {
      groupId: groupName,
      recoveryPlan,
      Members: selectedGroup.Members,
      password: groupPassword,
    };

    const groupDoc = doc(db, 'groups', selectedGroup.id);
    await updateDoc(groupDoc, updatedGroup);

    onClose();
  };

  const handleDeleteGroup = async () => {
    if (!selectedGroup) return;

    // Set groupId, recoveryPlan, and recoveryPlanDate to null for each member
    for (const user of users) {
      const userDoc = doc(db, 'users', user.id);
      if (selectedGroup.Members.includes(user.email)) {
        await updateDoc(userDoc, {
          groupId: null,
          recoveryPlan: null,
          recoveryPlanDate: null, // assuming recoveryPlanDate exists in the user data
        });
      }
    }

    // Delete the group from Firestore
    const groupDoc = doc(db, 'groups', selectedGroup.id);
    await deleteDoc(groupDoc);

    // Clear the selected group state and close the modal
    setSelectedGroup(null);
    onClose();
    window.location.reload();

  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white h-5/6 w-11/12 lg:w-3/4 xl:w-2/3 p-8 rounded-3xl shadow-2xl relative overflow-auto">

        <button className="btn btn-sm btn-circle absolute right-2 top-2" onClick={onClose}>✕</button>

        <h2 className="text-3xl font-semibold text-gray-800 mb-6">Modify Group</h2>

        {selectedGroup && (
          <>
            <div className="mb-6">
              <label className="block text-lg font-medium text-gray-700 mb-2">Group Name</label>
              <input
                type="text"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                className="select select-bordered w-full"
              />
            </div>

            <div className="mb-6">
              <label className="block text-lg font-medium text-gray-700 mb-2">Recovery Plan</label>
              <select
                value={recoveryPlan}
                onChange={(e) => setRecoveryPlan(e.target.value)}
                className="select select-bordered w-full"
              >
                <option value="" disabled>Select a recovery plan</option>
                {recoveryPlans.map(plan => (
                  <option key={plan} value={plan}>{plan}</option>
                ))}
              </select>
            </div>

            <div className="mb-6">
              <label className="block text-lg font-medium text-gray-700 mb-2">Group Password</label>
              <input
                value={groupPassword}
                onChange={(e) => setGroupPassword(e.target.value)}
                className="select select-bordered w-full"
              />
            </div>

            {/* Display the Admin */}
            {adminUser && (
              <div className="mb-6">
                <h3 className="text-xl font-medium text-gray-800 mb-2">Admin</h3>
                <div className="p-3 border border-gray-200 rounded-lg bg-gray-100">
                  <p className="text-sm font-medium text-gray-900">{adminUser.name}</p>
                  <p className="text-sm text-gray-500">{adminUser.email}</p>
                </div>
              </div>
            )}

            <div className="mb-6">
              <h3 className="text-xl font-medium text-gray-800 mb-4">Current Members</h3>
              <div className="max-h-64 overflow-y-auto border border-gray-200 rounded-lg">
                {filteredMembers.map(user => (
                  <div key={user.id} className="flex items-center justify-between p-3 border-b border-gray-100 hover:bg-gray-50">
                    <div>
                      <p className="text-sm font-medium text-gray-900">{user.name}</p>
                      <p className="text-sm text-gray-500">{user.email}</p>
                    </div>
                    {user.email !== selectedGroup.Admin ? ( // Disable delete for the admin
                      <button
                        className="btn btn-sm bg-red-500 text-white"
                        onClick={() => handleDeleteMember(user.email)}
                      >
                        Delete
                      </button>
                    ) : (
                      <button className="btn btn-sm bg-gray-300 text-white" disabled>
                        Admin
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className="flex justify-start space-x-4">
              <button
                onClick={handleUpdateGroup}
                className="btn mx-2 p-2"
              >
                Update Group
              </button>

              <button
                onClick={handleDeleteGroup}
                className="btn mx-2 p-2 bg-red-500"
              >
                Delete Group
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ModifyGroup;