import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, addDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Import Firestore instance from your firebase configuration

interface User {
  id: string;
  name: string;
  email: string;
  groupId: string | null;
}

const NewGroup2: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [groupName, setGroupName] = useState('');
  const [recoveryPlan, setRecoveryPlan] = useState('');
  const [recoveryPlans, setRecoveryPlans] = useState<string[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedAdmin, setSelectedAdmin] = useState<string | null>(null); // Track the admin user ID
  const [selectedMembers, setSelectedMembers] = useState<Set<string>>(new Set());
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      const usersData: User[] = usersSnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name,
        email: doc.data().email,
        groupId: doc.data().groupId || null,
      }));
      setUsers(usersData);
    };

    const fetchRecoveryPlans = async () => {
      const recoveryPlansCollection = collection(db, 'recoveryPlans');
      const recoveryPlansSnapshot = await getDocs(recoveryPlansCollection);
      const plans = recoveryPlansSnapshot.docs.map(doc => doc.id);
      setRecoveryPlans(plans);
    };

    fetchUsers();
    fetchRecoveryPlans();
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const handleUserSelect = (userId: string, type: 'admin' | 'member') => {
    if (type === 'admin') {
      if (selectedAdmin === userId) {
        setSelectedAdmin(null);
        setSelectedMembers(prev => {
          const newSet = new Set(prev);
          newSet.delete(userId);
          return newSet;
        });
      } else {
        setSelectedAdmin(userId);
        setSelectedMembers(prev => new Set([...prev, userId]));
      }
    } else {
      const newSelectedSet = new Set(selectedMembers);
      if (newSelectedSet.has(userId)) {
        newSelectedSet.delete(userId);
      } else {
        newSelectedSet.add(userId);
      }
      setSelectedMembers(newSelectedSet);
    }
  };

  const handleCreateGroup = async () => {
    if (!selectedAdmin) {
      alert('Please assign an admin before creating the group.');
      return;
    }

    const adminEmail = users.find(user => user.id === selectedAdmin)?.email;

    const newGroup = {
      groupId: groupName,
      recoveryPlan,
      password,
      Admin: adminEmail,
      Members: Array.from(new Set([...selectedMembers])).map(memberId => users.find(user => user.id === memberId)?.email),
    };

    await addDoc(collection(db, 'groups'), newGroup);

    for (const userId of selectedMembers) {
      const userDoc = doc(db, 'users', userId);
      await updateDoc(userDoc, { groupId: groupName, recoveryPlan });
    }

    onClose();
    window.location.reload();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
    <div className="bg-white h-5/6 w-11/12 lg:w-3/4 xl:w-2/3 p-8 rounded-3xl shadow-2xl relative overflow-auto">
    <button className="btn btn-sm btn-circle absolute right-2 top-2" onClick={onClose}>✕</button>

      <h2 className="text-3xl font-semibold text-gray-800 mb-6">Create a New Group</h2>
  
      <div className="mb-6">
        <label className="block text-lg font-medium text-gray-700 mb-2">Group Name</label>
        <input
          type="text"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
          className="select select-bordered w-full"
          placeholder="Enter group name"
        />
      </div>
  
      <div className="mb-6">
        <label className="block text-lg font-medium text-gray-700 mb-2">Recovery Plan</label>
        <select
          value={recoveryPlan}
          onChange={(e) => setRecoveryPlan(e.target.value)}
          className="select select-bordered w-full"
        >
          <option value="" disabled>Select a recovery plan</option>
          {recoveryPlans.map(plan => (
            <option key={plan} value={plan}>{plan}</option>
          ))}
        </select>
      </div>
  
      <div className="mb-6">
        <label className="block text-lg font-medium text-gray-700 mb-2">Group Password</label>
        <input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="select select-bordered w-full"
          placeholder="Enter group password"
        />
      </div>
  
      <div className="mb-6">
        <h3 className="text-xl font-medium text-gray-800 mb-4">Assign Admin and Members</h3>
        <input
          type="text"
          placeholder="Search users"
          value={searchQuery}
          onChange={handleSearch}
          className="select select-bordered w-full"
        />
        <div className="max-h-64 overflow-y-auto border border-gray-200 rounded-lg">
          {users
            .filter(user => (user.name.toLowerCase().includes(searchQuery) || user.email.toLowerCase().includes(searchQuery)) && !user.groupId)
            .map((user) => (
            <div key={user.id} className="flex items-center justify-between p-3 border-b border-gray-100 hover:bg-gray-50">
              <div>
                <p className="text-sm font-medium text-gray-900">{user.name}</p>
                <p className="text-sm text-gray-500">{user.email}</p>
              </div>
              <div className="flex gap-4">
                <label className="flex items-center">
                  <span className="text-sm font-medium text-gray-600">Admin</span>
                  <input
                    type="checkbox"
                    checked={selectedAdmin === user.id}
                    onChange={() => handleUserSelect(user.id, 'admin')}
                    className="checkbox checkbox-primary"
                    />
                </label>
                <label className="flex items-center">
                  <span className="text-sm font-medium text-gray-600">Member</span>
                  <input
                    type="checkbox"
                    checked={selectedMembers.has(user.id)}
                    onChange={() => handleUserSelect(user.id, 'member')}
                    className="checkbox checkbox-primary"
                    disabled={selectedAdmin === user.id} // Admin is automatically a member
                  />
                </label>
              </div>
            </div>
          ))}
        </div>
      </div>
  
      <div className="text-right">
        <button
          onClick={handleCreateGroup}
          className="btn mx-2 p-2"
          >
          Create Group
        </button>
      </div>
    </div>
  </div>
  );
};

export default NewGroup2;