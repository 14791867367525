import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Import Firestore instance from your firebase configuration
import { useUserLevel, AdminLevel } from '../../admin';

interface User {
  id: string;
  name: string;
  email: string;
  groupId: string | null;
}

interface Group {
  id: string;
  groupId: string;
  recoveryPlan: string;
  Admin: string; // Admin is now a single email string
  Members: string[];
  password?: string;
}

interface ModifyGroupProps {
  onClose: () => void;
}

const ModifyGroup: React.FC<ModifyGroupProps> = ({ onClose }) => {
  const { currentUserLevel } = useUserLevel();
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [groupName, setGroupName] = useState('');
  const [recoveryPlan, setRecoveryPlan] = useState('');
  const [groupPassword, setGroupPassword] = useState('');
  const [recoveryPlans, setRecoveryPlans] = useState<string[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [filteredMembers, setFilteredMembers] = useState<User[]>([]);
  const [selectedAdmin, setSelectedAdmin] = useState<string | null>(null); // Single admin email
  const [selectedMembers, setSelectedMembers] = useState<Set<string>>(new Set());
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [groups, setGroups] = useState<Group[]>([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      const usersData: User[] = usersSnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name,
        email: doc.data().email,
        groupId: doc.data().groupId || null,
      }));
      setUsers(usersData);
    };

    const fetchRecoveryPlans = async () => {
      const recoveryPlansCollection = collection(db, 'recoveryPlans');
      const recoveryPlansSnapshot = await getDocs(recoveryPlansCollection);
      const plans = recoveryPlansSnapshot.docs.map(doc => doc.id);
      setRecoveryPlans(plans);
    };

    const fetchGroups = async () => {
      const groupsCollection = collection(db, 'groups');
      const groupsSnapshot = await getDocs(groupsCollection);
      const groupsData: Group[] = groupsSnapshot.docs.map(doc => ({
        id: doc.id,
        groupId: doc.data().groupId,
        recoveryPlan: doc.data().recoveryPlan,
        Admin: doc.data().Admin, // Now admin is a single email
        Members: doc.data().Members || [],
        password: doc.data().password || '',
      }));
      setGroups(groupsData);
    };

    fetchUsers();
    fetchRecoveryPlans();
    fetchGroups();
  }, []);

  useEffect(() => {
    if (selectedGroup) {
      setGroupName(selectedGroup.groupId);
      setRecoveryPlan(selectedGroup.recoveryPlan);
      setGroupPassword(selectedGroup.password || '');

      const adminEmail = selectedGroup.Admin;
      const memberIds = new Set(users.filter(user => selectedGroup.Members.includes(user.email)).map(user => user.id));

      setSelectedAdmin(users.find(user => user.email === adminEmail)?.id || null);
      setSelectedMembers(memberIds);

      setFilteredMembers(users.filter(user => memberIds.has(user.id)));
    }
  }, [selectedGroup, users]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const handleUserSelect = (userId: string, type: 'admin' | 'member') => {
    if (currentUserLevel === AdminLevel.USER) return;
    if (type === 'admin') {
      if (selectedAdmin === userId) {
        setSelectedAdmin(null);
        setSelectedMembers(prev => {
          const newSet = new Set(prev);
          newSet.delete(userId);
          return newSet;
        });
      } else {
        setSelectedAdmin(userId);
        setSelectedMembers(prev => new Set([...prev, userId]));
      }
    } else {
      const newSelectedSet = new Set(selectedMembers);
      if (newSelectedSet.has(userId)) {
        newSelectedSet.delete(userId);
      } else {
        newSelectedSet.add(userId);
      }
      setSelectedMembers(newSelectedSet);
    }
  };

  const handleUpdateGroup = async () => {
    if (!selectedGroup || !selectedAdmin) return;

    const adminEmail = users.find(user => user.id === selectedAdmin)?.email;
    const memberEmails = Array.from(new Set([...selectedMembers])).map(memberId => users.find(user => user.id === memberId)?.email);

    const updatedGroup = {
      groupId: groupName,
      recoveryPlan,
      Admin: adminEmail, // Single admin email
      Members: memberEmails,
      password: groupPassword,
    };

    const groupDoc = doc(db, 'groups', selectedGroup.id);
    await updateDoc(groupDoc, updatedGroup);

    for (const user of users) {
      const userDoc = doc(db, 'users', user.id);
      if (selectedMembers.has(user.id)) {
        await updateDoc(userDoc, {
          groupId: groupName,
          recoveryPlan,
        });
      } else if (user.groupId === selectedGroup?.groupId) {
        await updateDoc(userDoc, {
          groupId: null,
          recoveryPlan: null,
        });
      }
    }

    onClose();
  };

  const handleDeleteGroup = async () => {
    if (!selectedGroup) return;

    // Set groupId, recoveryPlan, and recoveryPlanDate to null for each member
    for (const user of users) {
      const userDoc = doc(db, 'users', user.id);
      if (selectedGroup.Members.includes(user.email)) {
        await updateDoc(userDoc, {
          groupId: null,
          recoveryPlan: null,
          recoveryPlanDate: null, // assuming recoveryPlanDate exists in the user data
        });
      }
    }

    // Delete the group from Firestore
    const groupDoc = doc(db, 'groups', selectedGroup.id);
    await deleteDoc(groupDoc);

    // Clear the selected group state and close the modal
    setSelectedGroup(null);
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white h-5/6 w-11/12 lg:w-3/4 xl:w-2/3 p-8 rounded-3xl shadow-2xl relative overflow-auto">

        <button className="btn btn-sm btn-circle absolute right-2 top-2" onClick={onClose}>✕</button>

        <h2 className="text-3xl font-semibold text-gray-800 mb-6">Modify Group</h2>

        <div className="mb-6">
          <label className="block text-lg font-medium text-gray-700 mb-2">Select Group</label>
          <select
            value={selectedGroup?.id || ''}
            onChange={(e) => setSelectedGroup(groups.find(group => group.id === e.target.value) || null)}
            className="select select-bordered w-full"
            >
            <option value="" disabled>Select a group</option>
            {groups.map(group => (
              <option key={group.id} value={group.id}>{group.groupId}</option>
            ))}
          </select>
        </div>

        {selectedGroup && (
          <>
            <div className="mb-6">
              <label className="block text-lg font-medium text-gray-700 mb-2">Group Name</label>
              <input
                type="text"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                className="select select-bordered w-full"
                />
            </div>

            <div className="mb-6">
              <label className="block text-lg font-medium text-gray-700 mb-2">Recovery Plan</label>
              <select
                value={recoveryPlan}
                onChange={(e) => setRecoveryPlan(e.target.value)}
                className="select select-bordered w-full"
                >
                <option value="" disabled>Select a recovery plan</option>
                {recoveryPlans.map(plan => (
                  <option key={plan} value={plan}>{plan}</option>
                ))}
              </select>
            </div>

            <div className="mb-6">
              <label className="block text-lg font-medium text-gray-700 mb-2">Group Password</label>
              <input
                value={groupPassword}
                onChange={(e) => setGroupPassword(e.target.value)}
                className="select select-bordered w-full"
                />
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-medium text-gray-800 mb-4">Assign Admin and Members</h3>
              <input
                type="text"
                placeholder="Search users"
                value={searchQuery}
                onChange={handleSearch}
                className="select select-bordered w-full"
                />
              <div className="max-h-64 overflow-y-auto border border-gray-200 rounded-lg">
                {users
                  .filter(user => (user.name.toLowerCase().includes(searchQuery) || user.email.toLowerCase().includes(searchQuery)))
                  .map((user) => (
                  <div key={user.id} className="flex items-center justify-between p-3 border-b border-gray-100 hover:bg-gray-50">
                    <div>
                      <p className="text-sm font-medium text-gray-900">{user.name}</p>
                      <p className="text-sm text-gray-500">{user.email}</p>
                    </div>
                    <div className="flex gap-4">
                      <label className="flex items-center">
                        <span className="text-sm font-medium text-gray-600">Admin</span>
                        <input
                          type="checkbox"
                          checked={selectedAdmin === user.id}
                          onChange={() => handleUserSelect(user.id, 'admin')}
                          className="checkbox checkbox-primary"
                        />
                      </label>
                      <label className="flex items-center">
                        <span className="text-sm font-medium text-gray-600">Member</span>
                        <input
                          type="checkbox"
                          checked={selectedMembers.has(user.id)}
                          onChange={() => handleUserSelect(user.id, 'member')}
                          className="checkbox checkbox-primary"
                          disabled={selectedAdmin === user.id} // Admin is automatically a member
                        />
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex justify-start space-x-4">
              <button
                onClick={handleUpdateGroup}
                className="btn mx-2 p-2"
              >
                Update Group
              </button>

              <button
                onClick={handleDeleteGroup}
                className="btn mx-2 p-2 bg-red-500"
              >
                Delete Group
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ModifyGroup;
