import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from "../../firebase";

interface ScheduleProps {
    userId: string | undefined;
  }
  
  interface ScheduleItem {
    id: string;
    completedAssessments: number;
    completedTreatments: number;
    recoveryPlan: string;
    startDate: string;
    status: string;
    streak: number;
    totalAssessments: number;
    totalTreatments: number;
    week1: Record<string, Record<string, boolean>>;
    week2?: Record<string, Record<string, boolean>>;
    week3?: Record<string, Record<string, boolean>>;
    week4?: Record<string, Record<string, boolean>>;
    week5?: Record<string, Record<string, boolean>>;
    week6?: Record<string, Record<string, boolean>>;
    week7?: Record<string, Record<string, boolean>>;
    week8?: Record<string, Record<string, boolean>>;
    week9?: Record<string, Record<string, boolean>>;
    week10?: Record<string, Record<string, boolean>>;
    week11?: Record<string, Record<string, boolean>>;
    week12?: Record<string, Record<string, boolean>>;
  }
  
  const Schedule: React.FC<ScheduleProps> = ({ userId }) => {
    const [schedules, setSchedules] = useState<ScheduleItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [activeTab, setActiveTab] = useState<string | null>(null);
    const [page, setPage] = useState<number>(0);
    const PAGE_SIZE = 20;
  
    useEffect(() => {
      const fetchSchedules = async () => {
        try {
          const q = query(collection(db, 'user_schedules'), where('userId', '==', userId));
          const querySnapshot = await getDocs(q);
          const fetchedSchedules: ScheduleItem[] = [];
  
          querySnapshot.forEach((doc) => {
            const data = { id: doc.id, ...doc.data() } as ScheduleItem;
            fetchedSchedules.push(data);
          });
  
          fetchedSchedules.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

          setSchedules(fetchedSchedules);
        } catch (error) {
          console.error("Error fetching user's schedule: ", error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchSchedules();
    }, [userId]);
  
    if (loading) {
      return <p>Loading schedules...</p>;
    }
  
    const paginatedSchedules = schedules.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE);
    return (
      <div>
        <div className="overflow-x-auto whitespace-nowrap mb-6">
            <div className="inline-flex space-x-4">
                {paginatedSchedules.map(item => (
                <div
                    key={item.id}
                    className="p-4 border rounded-lg shadow-md cursor-pointer hover:bg-gray-100 inline-block"
                    onClick={() => setActiveTab(item.id === activeTab ? null : item.id)}
                >
                    <p><strong>Recovery Plan:</strong> {item.recoveryPlan}</p>
                    <p><strong>Start Date:</strong> {item.startDate}</p>
                    <p><strong>Status:</strong> {item.status}</p>
                </div>
                ))}
            </div>
            </div>
  
        {activeTab && (
          <div className="border rounded-lg p-6">
            <ScheduleCard item={schedules.find(schedule => schedule.id === activeTab)!} />
          </div>
        )}

      </div>
    );
  };
  
  const ScheduleCard: React.FC<{ item: ScheduleItem }> = ({ item }) => {
      const daysOrder = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
    
      const formatTask = (task: string) => {
        if (task.includes('Treatment')) {
          return 'Treatment';
        } else if (task.includes('Back Assessment')) {
          return 'Back Assessment';
        } else if (task.includes('Arm Assessment')) {
          return 'Arm Assessment';
        } else if (task.includes('Knee Assessment')) {
          return 'Knee Assessment';
        } else {
          return task.replace(/-/g, ' ');
        }
      };
    
      const renderWeekProgress = (weekData: Record<string, Record<string, boolean>>, weekNumber: number) => (
        <div key={`week${weekNumber}`} className="mb-8">
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-xl font-semibold text-gray-800">Week {weekNumber}</h3>
          </div>
          <table className="table-auto w-full border border-gray-300">
            <thead>
              <tr>
                {daysOrder.map((day) => (
                  <th key={day} className="text-center border border-gray-300">
                    {day.charAt(0).toUpperCase() + day.slice(1)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {daysOrder.map((day) => (
                  <td key={day} className="p-4 border border-gray-300">
                    {weekData[day] ? (
                      <ul className="space-y-2">
                        {Object.entries(weekData[day]).map(([task, completed]) => (
                          <li key={task} className={`px-2 py-1 text-sm rounded-full text-center ${completed ? 'bg-schedule-c' : 'bg-schedule-nc'}`}>
                            {formatTask(task)} {completed ? '✓' : ''}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>No tasks</p>
                    )}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      );
    
      return (
        <div className="mb-6 p-6 rounded-lg bg-white">
          <p className="text-lg font-semibold text-gray-900"><strong>Recovery Plan:</strong> {item.recoveryPlan}</p>
          <p className="text-gray-700 mt-2"><strong>Start Date:</strong> {item.startDate}</p>

          <p className="text-gray-700">
            <span
                className={`font-bold ${item.status === 'active' ? 'text-green-500' : item.status === 'terminated' ? 'text-red-500' : 'text-gray-500'}`}
            >
                {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
            </span>
            </p>
          <div className="flex items-center space-x-4 mt-2">
          <p>
            <strong className="text-[#9747ff]">Treatments:</strong> {item.completedTreatments} / {item.totalTreatments}
            </p>
            <p>
            <strong  className="text-[#56c1ca]">Assessments:</strong> {item.completedAssessments} / {item.totalAssessments}
            </p>
            <p>
            <strong >Streak:</strong> {item.streak}
            </p>
          </div>
          <h3 className="text-2xl font-semibold text-gray-800 mb-4 mt-4">Progress</h3>
          {renderWeekProgress(item.week1, 1)}
          {item.week2 && renderWeekProgress(item.week2, 2)}
          {item.week3 && renderWeekProgress(item.week3, 3)}
          {item.week4 && renderWeekProgress(item.week4, 4)}
          {item.week5 && renderWeekProgress(item.week5, 5)}
          {item.week6 && renderWeekProgress(item.week6, 6)}
          {item.week7 && renderWeekProgress(item.week7, 7)}
          {item.week8 && renderWeekProgress(item.week8, 8)}
          {item.week9 && renderWeekProgress(item.week9, 9)}
          {item.week10 && renderWeekProgress(item.week10, 10)}
          {item.week11 && renderWeekProgress(item.week11, 11)}
          {item.week12 && renderWeekProgress(item.week12, 12)}
        </div>
      );
    };
  
  export default Schedule;


