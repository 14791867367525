import React, { useState, useEffect } from 'react';
import { collection, doc, getDocs, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Import Firestore instance from your firebase configuration

interface ModifySequenceProps {
  onClose: () => void;
  onModify: (productCode: string, sequence: any) => void;
}

const ModifySequence: React.FC<ModifySequenceProps> = ({onClose, onModify }) => {
  const [sequences, setSequences] = useState<{ [key: string]: any[] }>({});

  useEffect(() => {
    const fetchSequences = async () => {
      const sequencesCollection = collection(db, 'sequences');
      const sequencesSnapshot = await getDocs(sequencesCollection);
      const sequencesData: { [key: string]: any[] } = {};

      sequencesSnapshot.forEach(doc => {
        sequencesData[doc.id] = [];
        const sequences = doc.data();
        Object.keys(sequences).forEach(key => {
          if (key.startsWith('sequence')) {
            sequencesData[doc.id].push({ id: key, ...sequences[key] });
          }
        });
      });
      setSequences(sequencesData);
    };

    fetchSequences();
  }, []);

  const handleDelete = async (productCode: string, sequenceId: string) => {
    const sequenceDoc = doc(db, 'sequences', productCode);
    const snapshot = await getDoc(sequenceDoc);
    if (snapshot.exists()) {
      const sequences = snapshot.data();
      delete sequences[sequenceId];
      await setDoc(sequenceDoc, sequences);
      setSequences(prev => ({
        ...prev,
        [productCode]: prev[productCode].filter(seq => seq.id !== sequenceId)
      }));
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white h-5/6 w-11/12 lg:w-3/4 xl:w-2/3 p-8 rounded-3xl shadow-2xl relative overflow-auto">
        <button className="btn btn-sm btn-circle absolute right-2 top-2" onClick={onClose}>
          ✕
        </button>
        <h2 className="text-3xl font-semibold text-gray-800 mb-6">Modify Sequences</h2>
  
        <div className="overflow-y-auto max-h-full">
          {Object.keys(sequences).map((productCode) => (
            <div key={productCode} className="mb-8">
              <h3 className="text-xl font-semibold text-gray-700 mb-4">{productCode}</h3>
              <div className="space-y-4">
                {sequences[productCode].map((sequence) => (
                  <div key={sequence.id} className="bg-gray-50 p-6 rounded-lg shadow-md">
                    <div className="mb-4">
                      <p className="text-sm"><strong>Product Code:</strong> {sequence.productCode}</p>
                      <p className="text-sm"><strong>Index:</strong> {sequence.index}</p>
                      <p className="text-sm"><strong>Repeat:</strong> {sequence.repeat}</p>
                    </div>
                    <div>
                      <h4 className="text-sm font-semibold mb-2">Phases:</h4>
                      <ul className="pl-4 space-y-2">
                        {['phase1', 'phase2', 'phase3', 'phase4', 'phase5'].map((phaseKey, idx) => {
                          const phase = sequence[phaseKey];
                          return (
                            <li key={phaseKey} className="text-sm">
                              <strong>Phase {idx + 1}:</strong> Pattern: {phase.pattern}, Intensity: {phase.intensity}, Length: {phase.length}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="flex mt-4 space-x-4">
                      <button
                        onClick={() => onModify(productCode, sequence)}
                        className="btn btn-primary btn-sm w-28"
                      >
                        Modify
                      </button>
                      <button
                        onClick={() => handleDelete(productCode, sequence.id)}
                        className="btn btn-secondary btn-sm w-28"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ModifySequence;
