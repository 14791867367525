import React, { useState, useEffect } from 'react';
import { Line, CartesianGrid, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, ComposedChart, Bar } from 'recharts';
import { DateTime } from 'luxon';
import { exportTreatmentsToCsv } from "../../utils";
import UserProfile from '../../routes/userprofile';

interface DataItem {
  id: string;
  startTime: DateTime | null;
  endTime: DateTime | null;
  sequence: { [product: string]: number } | null;
  length: number;
  deviceId: string;
  temperaturePre: number;
  temperaturePost: number;
  painScorePre: number;
  painScorePost: number;
  deviceTN: string;
}

interface TreatmentGraphProps {
  data: DataItem[];
  userEmail: string | null | undefined;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: any;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    return (
      <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc', fontSize: '12px' }}>
        <p>Date: {data.startTime?.toFormat('dd/MM/yyyy HH:mm')}</p>
        <p>Treatment: {data.length} minutes</p>
        <p>Temperature: {data.temperaturePost}°C</p>
        <p>Pain Before: {data.painScorePre}</p>
        <p>Pain After: {data.painScorePost}</p>
        <p>Product: {Object.keys(data.sequence)}</p>
        <p>Device ID: {data.deviceId}</p>
      </div>
    );
  }

  return null;
};

const initialLineVisibility = [true, true, true, true];

const TreatmentGraph: React.FC<TreatmentGraphProps> = ({ data, userEmail }) => {
  const [lineVisibility, setLineVisibility] = useState<boolean[]>(initialLineVisibility);
  const [filteredData, setFilteredData] = useState<DataItem[]>([]);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [daysShown, setDaysShown] = useState<number>(7);
  const [currentStart, setCurrentStart] = useState<DateTime>(DateTime.now().minus({ days: 6 }).startOf('day'));
  const [currentEnd, setCurrentEnd] = useState<DateTime>(DateTime.now().endOf('day'));

  const timeRanges = [
    { label: 'Single Day', value: 1 },
    { label: '7 Days', value: 7 },
    { label: '1 Month', value: 28 },
    { label: '3 Months', value: 84 },
  ];

  const toggleLineVisibility = (index: number) => {
    const newVisibility = [...lineVisibility];
    newVisibility[index] = !newVisibility[index];
    setLineVisibility(newVisibility);
  };

  const handlePrevious = () => {
    const offset = daysShown === 1 ? { days: 1 } : { days: daysShown };
    setCurrentStart(currentStart.minus(offset));
    setCurrentEnd(currentEnd.minus(offset));
  };

  const handleNext = () => {
    const offset = daysShown === 1 ? { days: 1 } : { days: daysShown };
    setCurrentStart(currentStart.plus(offset));
    setCurrentEnd(currentEnd.plus(offset));
  };

  const handleTimeRangeChange = (days: number) => {
    setDaysShown(days);
    const newStart =
      days === 1
        ? DateTime.now().startOf('day')
        : DateTime.now().minus({ days: days - 1 }).startOf('day');
    const newEnd = DateTime.now().endOf('day');
    setCurrentStart(newStart);
    setCurrentEnd(newEnd);
  };

  useEffect(() => {
    const filterData = (start: DateTime, end: DateTime, dataSource: DataItem[]) => {
      return dataSource.filter(item => {
        const itemDate = item.startTime;
        return itemDate && itemDate >= start && itemDate <= end;
      });
    };

    const filtered = filterData(currentStart, currentEnd, data);
    setFilteredData(filtered);
  }, [data, currentStart, currentEnd]);

  const transformedData = filteredData.map(item => ({
    ...item,
    timeStamp: item.startTime ? item.startTime.toMillis() : 0,
    product: item.sequence ? Object.keys(item.sequence)[0] : '',
    index: item.sequence ? Object.values(item.sequence)[0] : 0,
  }));


  const ticks = daysShown === 1 ?  Array.from({ length: 24 }).map((_, i) => currentStart.plus({ hours: i }).toMillis())
  : daysShown === 7 ? Array.from({ length: 7 }).map((_, i) => currentStart.plus({ days: i }).toMillis())
  : daysShown === 28 ? Array.from({ length: 4 }).map((_, i) => currentStart.plus({ weeks: i }).toMillis())
  : Array.from({ length: 3 }).map((_, i) => currentStart.plus({ months: i }).toMillis());

  const formatTick = (tick: number) =>
    DateTime.fromMillis(tick).toFormat(daysShown > 7 ? 'dd/MMM' : daysShown === 7 ? 'EEE dd/MMM' : 'HH:mm');
    
  const isNextDisabled = currentEnd >= DateTime.now().endOf('day');

  return (
    <div className="card" style={{height: '500px'}}>
      <span className="text-blue-500 cursor-pointer underline text-center" style={{fontSize: '12px'}}
        onClick={() => { exportTreatmentsToCsv("treatments.csv", data as any[], userEmail!) }} // Export all treatment data
      >
        Export all to CSV
      </span>
      <div className="flex justify-between items-center mb-4 p-4">
        <button className="btn" style={{fontSize: '20px'}} onClick={handlePrevious}>←</button>
        <h2>{currentStart.toFormat('dd/MM/yyyy')} - {currentEnd.toFormat('dd/MM/yyyy')}</h2>
        <button className="btn" style={{fontSize: '20px'}} onClick={handleNext} disabled={isNextDisabled}>→</button>
        <div className="flex">
          <select
            className="dropdown-selector p-2 rounded border"
            value={daysShown}
            onChange={(e) => handleTimeRangeChange(Number(e.target.value))}
          >
            {timeRanges.map((range) => (
              <option key={range.value} value={range.value}>
                {range.label}
              </option>
            ))}
          </select>
        </div>
        <h3 style={{ position: 'relative', left: '0px', fontSize: '14px'}}>Table Display</h3>
        <label className="switch" style={{right: '30px'}}>
          <input type="checkbox" checked={showTable} onChange={() => setShowTable(!showTable)} />
          <span className="slider"></span>
        </label>
      </div>
      {!showTable ? (
        <div style={{height: '200px'}}>
          <ResponsiveContainer>
            <ComposedChart
              data={transformedData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <Tooltip content={<CustomTooltip />} />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                interval={0}
                ticks={ticks}
                tickFormatter={formatTick}
                dataKey="timeStamp"
                type="number"
                domain={[currentStart.toMillis(), currentEnd.toMillis()]}
                scale="time"
                style={{ fontSize: '12px' }}
              />
              <YAxis
                yAxisId="t"
                domain={[0, 30]}
                ticks={[10, 20, 30]}
                axisLine={{ stroke: '#AAAAAA' }}
                tickLine={{ stroke: '#AAAAAA' }}
                tick={{ fill: '#000000', fontSize: '12px' }}
              />
              <YAxis
                yAxisId="temp"
                domain={[0, 70]}
                hide
              />
              <YAxis
                yAxisId="p"
                domain={[0, 10]}
                hide
              />
              {lineVisibility[0] && <Bar yAxisId="t" fill="#9747ff" dataKey="length" barSize={4}
                isAnimationActive={false}
              />}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <div className="table-responsive" style={{height: '300px'}}>
          <table className="table table-zebra" style={{ fontSize: '12px' }}>
            <thead>
              <tr>
                <th>Device</th>
                <th>Device Treatment #</th>
                <th>Start Time</th>
                <th>Duration (mins)</th>
                <th>End Time</th>
                <th>Product</th>
                <th>Sequence</th>
                <th>Temperature Pre</th>
                <th>Temperature Post</th>
                <th>Pain Score Pre</th>
                <th>Pain Score Post</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((row) => (
                <tr key={row.id}>
                  <td>{row.deviceId}</td>
                  <td>{row.deviceTN}</td>
                  <td>{row.startTime?.toFormat('yyyy-MM-dd HH:mm')}</td>
                  <td>{row.length}</td>
                  <td>{row.endTime?.toFormat('yyyy-MM-dd HH:mm')}</td>
                  <td>{Object.keys(row.sequence || {})[0]}</td>
                  <td>{Object.values(row.sequence || {})[0]}</td>
                  <td>{row.temperaturePre}</td>
                  <td>{row.temperaturePost}</td>
                  <td>{row.painScorePre}</td>
                  <td>{row.painScorePost}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default TreatmentGraph;