import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, addDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Import Firestore instance from your firebase configuration

interface User {
  id: string;
  name: string;
  email: string;
  groupId: string | null;
}

const NewGroup2: React.FC<{ onClose: () => void, email: string, userId: string }> = ({ onClose, email, userId }) => {
  const [groupName, setGroupName] = useState('');
  const [recoveryPlan, setRecoveryPlan] = useState('');
  const [recoveryPlans, setRecoveryPlans] = useState<string[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<Set<string>>(new Set());
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const fetchRecoveryPlans = async () => {
      const recoveryPlansCollection = collection(db, 'recoveryPlans');
      const recoveryPlansSnapshot = await getDocs(recoveryPlansCollection);
      const plans = recoveryPlansSnapshot.docs.map(doc => doc.id);
      setRecoveryPlans(plans);
    };

    fetchRecoveryPlans();
  }, []);

  const handleCreateGroup = async () => {
    const newGroup = {
      groupId: groupName,
      recoveryPlan,
      password,
      Admin: email,
      Members: [email], // Add the admin as the first member
    };

    // Create the group in Firestore
    await addDoc(collection(db, 'groups'), newGroup);

    // Update the admin's user information
    const userDoc = doc(db, 'users', userId);
    await updateDoc(userDoc, {
      groupId: groupName,
      recoveryPlan,
    });

    // Optionally add other members to the group, if needed
    for (const memberId of selectedMembers) {
      const memberDoc = doc(db, 'users', memberId);
      await updateDoc(memberDoc, { groupId: groupName, recoveryPlan });
    }

    onClose();
    window.location.reload();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white h-5/6 w-11/12 lg:w-3/4 xl:w-2/3 p-8 rounded-3xl shadow-2xl relative overflow-auto">
        <button className="btn btn-sm btn-circle absolute right-2 top-2" onClick={onClose}>✕</button>

        <h2 className="text-3xl font-semibold text-gray-800 mb-6">Create a New Group</h2>

        <div className="mb-6">
          <label className="block text-lg font-medium text-gray-700 mb-2">Group Name</label>
          <input
            type="text"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            className="select select-bordered w-full"
            placeholder="Enter group name"
          />
        </div>

        <div className="mb-6">
          <label className="block text-lg font-medium text-gray-700 mb-2">Recovery Plan</label>
          <select
            value={recoveryPlan}
            onChange={(e) => setRecoveryPlan(e.target.value)}
            className="select select-bordered w-full"
          >
            <option value="" disabled>Select a recovery plan</option>
            {recoveryPlans.map(plan => (
              <option key={plan} value={plan}>{plan}</option>
            ))}
          </select>
        </div>

        <div className="mb-6">
          <label className="block text-lg font-medium text-gray-700 mb-2">Group Password</label>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="select select-bordered w-full"
            placeholder="Enter group password"
          />
        </div>

        <label className="block text-lg font-medium text-gray-700 mb-2">Admin: {email}</label>

        <div className="text-right">
          <button
            onClick={handleCreateGroup}
            className="btn mx-2 p-2"
          >
            Create Group
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewGroup2;