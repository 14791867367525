const camel2title = (camelCase: string) => camelCase
  .replace(/([A-Z])/g, (match) => ` ${match}`)
  .replace(/^./, (match) => match.toUpperCase())
  .trim();

  export function exportTreatmentsToCsv(filename: string, rows: any[], userEmail: string | null) {
    // Helper function to process each row
    var processRow = function (row: Array<any>) {
      var finalVal = '';
      for (var j = 0; j < row.length; j++) {
        const value: any = row[j];
        var innerValue = '';
        if (value instanceof Date) {
          innerValue = value.toLocaleString(); // Format date
        } else {
          innerValue = value?.toString() || ''; // Convert other values to string
        }
        var result = innerValue.replace(/"/g, '""'); // Escape double quotes
        if (result.search(/("|,|\n)/g) >= 0)
          result = '"' + result + '"'; // Enclose in double quotes if necessary
        if (j > 0)
          finalVal += ',';
        finalVal += result;
      }
      return finalVal + '\n';
    };
  
    // Define headers for the CSV
    var headers = ['Index', 'Date', 'Length', 'Device ID', 'Device Treatment Number', 'Temperature Pre', 'Temperature Post', 'Pain Pre', 'Pain Post'];
  
    // Process the header row
    var csvFile = processRow(headers);
  
    // Map rows to their corresponding headers, with an index as the first column
    rows = rows.map((row: any, index: number) => [
      index + 1,  // Add Index
      row.startTime ? new Date(row.startTime).toLocaleString() : '', // Add Date
      row.length || '',  // Add Length
      row.deviceId || '',  // Add Device ID
      row.deviceTN || '',  // Add Device Treatment Number
      row.temperaturePre || '',  // Add Temperature Pre
      row.temperaturePost || '',  // Add Temperature Post
      row.painScorePre || '',  // Add Pain Pre
      row.painScorePost || '',  // Add Pain Post
    ]);
  
    // Process each row of data
    for (var i = 0; i < rows.length; i++) {
      csvFile += processRow(rows[i]);
    }
  
    // Add userEmail to the filename
    const finalFilename = `${userEmail}_${filename}`;
  
    // Create CSV file and trigger download
    var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    var link = document.createElement("a");
    if (link.download !== undefined) { // Feature detection for HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", finalFilename); // Set download filename
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

export function exportResearchToCsv(filename: string, rows: any[], userId: string | null) {
  // Helper function to process each row
  var processRow = function (row: Array<any>) {
    var finalVal = '';
    for (var j = 0; j < row.length; j++) {
      const value: any = row[j];
      var innerValue = '';
      if (value instanceof Date) {
        innerValue = value.toLocaleString(); // Format date
      } else {
        innerValue = value?.toString() || ''; // Convert other values to string
      }
      var result = innerValue.replace(/"/g, '""'); // Escape double quotes
      if (result.search(/("|,|\n)/g) >= 0)
        result = '"' + result + '"'; // Enclose in double quotes if necessary
      if (j > 0)
        finalVal += ',';
      finalVal += result;
    }
    return finalVal + '\n';
  };

  // Define headers explicitly for the CSV: Index, Date, Length, Frequency, Power
  var headers = ['Index', 'Date', 'Duration (minutes)', 'Frequency', 'Power'];

  // Process the header row
  var csvFile = processRow(headers);

  // Map rows to their corresponding headers, with an index as the first column
  rows = rows.map((row: any, index: number) => [
    index + 1,  // Add Index
    row.startTime || '',   
    row.length || '',   
    `${row.freqSingle ? `${row.freqSingle}Hz` : ''}`,  // Add Frequency
    `${row.powerSingle ? `${row.powerSingle}%` : ''}`  // Add Power
  ]);

  // Process the rows
  for (var i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  // Add userId to the filename
  const finalFilename = `${userId}_${filename}`;

  // Create CSV file and trigger download
  var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  var link = document.createElement("a");
  if (link.download !== undefined) { // Feature detection for HTML5 download attribute
    var url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", finalFilename); // Set download filename
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export function exportAssessmentsToCsv(
  filename: string,
  backData: any[],
  kneeData: any[],
  armData: any[],
  userEmail: string | null
) {
  const processRow = (row: Array<any>) => {
    let finalVal = '';
    for (let j = 0; j < row.length; j++) {
      const value: any = row[j];
      let innerValue = '';
      if (value instanceof Date) {
        innerValue = value.toLocaleString(); // Format date
      } else {
        innerValue = value?.toString() || ''; // Convert other values to string
      }
      let result = innerValue.replace(/"/g, '""'); // Escape double quotes
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"'; // Enclose in double quotes if necessary
      if (j > 0) finalVal += ',';
      finalVal += result;
    }
    return finalVal + '\n';
  };

  // Headers for the Back dataset
  const backHeaders = [
    'Index',
    'Date',
    'Device ID',
    'Flexion',
    'Extension',
    'Left Rotation',
    'Right Rotation',
    'Left Lateral Bend',
    'Right Lateral Bend',
  ];

  // Headers for the Knee dataset (Extension only)
  const kneeHeaders = ['Index', 'Date', 'Device ID', 'Extension'];

  // Headers for the Arm dataset (Flexion only)
  const armHeaders = ['Index', 'Date', 'Device ID', 'Flexion'];

  // Helper to process data for each dataset
  const processDataset = (data: any[], datasetName: string, headers: string[], mapRow: (row: any, index: number) => any[]) => {
    let csv = `\n${datasetName.toUpperCase()} ASSESSMENTS\n`; // Add dataset name as a section title
    csv += processRow(headers); // Add headers

    // Map rows to their corresponding headers
    const rows = data.map((row: any, index: number) => mapRow(row, index));

    // Process each row of data
    for (let i = 0; i < rows.length; i++) {
      csv += processRow(rows[i]);
    }

    return csv;
  };

  // Generate CSV content for all datasets
  let csvFile = '';
  csvFile += processDataset(
    backData,
    'Back',
    backHeaders,
    (row, index) => [
      index + 1,
      row.startTime ? new Date(row.startTime).toLocaleString() : '',
      row.deviceId || '',
      row.flexibilityScore1 || '',
      row.flexibilityScore2 || '',
      row.flexibilityScore3 || '',
      row.flexibilityScore4 || '',
      row.flexibilityScore5 || '',
      row.flexibilityScore6 || '',
    ]
  );

  csvFile += processDataset(
    kneeData,
    'Knee',
    kneeHeaders,
    (row, index) => [
      index + 1,
      row.startTime ? new Date(row.startTime).toLocaleString() : '',
      row.deviceId || '',
      row.flexibilityScore1 || '',
    ]
  );

  csvFile += processDataset(
    armData,
    'Arm',
    armHeaders,
    (row, index) => [
      index + 1,
      row.startTime ? new Date(row.startTime).toLocaleString() : '',
      row.deviceId || '',
      row.flexibilityScore1 || '',
    ]
  );

  // Add userEmail to the filename
  const finalFilename = `${userEmail}_${filename}`;

  // Create CSV file and trigger download
  const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', finalFilename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
export function exportMetricsToCsv(filename: string, rows: any[], userEmail: string | null) {
  // Helper function to process each row
  var processRow = function (row: Array<any>) {
    var finalVal = '';
    for (var j = 0; j < row.length; j++) {
      const value: any = row[j];
      var innerValue = '';
      if (value instanceof Date) {
        innerValue = value.toLocaleString(); // Format date
      } else {
        innerValue = value?.toString() || ''; // Convert other values to string
      }
      var result = innerValue.replace(/"/g, '""'); // Escape double quotes
      if (result.search(/("|,|\n)/g) >= 0)
        result = '"' + result + '"'; // Enclose in double quotes if necessary
      if (j > 0)
        finalVal += ',';
      finalVal += result;
    }
    return finalVal + '\n';
  };

  // Define headers for the CSV
  var headers = ['Index', 'Date', 'Mobility - Av', 'Pain - Av', 'Treatment - Total', 'Recovery'];

  // Process the header row
  var csvFile = processRow(headers);

  // Map rows to their corresponding headers, with an index as the first column
  rows = rows.map((row: any, index: number) => [
    index + 1,  // Add Index
    row.date || '', // Add Date
    Math.trunc(row.mobility.average ) || '',  // Add Flexion
    Math.trunc(row.pain.average) || '',  // Add Extension
    row.treatment.total || '',  // Add Left Rotation
    Math.trunc(row.recovery) || '',  // Add Right Rotation
  ]);

  // Process each row of data
  for (var i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  // Add userEmail to the filename
  const finalFilename = `${userEmail}_${filename}`;

  // Create CSV file and trigger download
  var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  var link = document.createElement("a");
  if (link.download !== undefined) { // Feature detection for HTML5 download attribute
    var url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", finalFilename); // Set download filename
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}