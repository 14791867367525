import React, { useState } from 'react';
import CreateSequence from './CreateSequence';
import ModifySequence from './ModifySequence';
import AssignSequence from './AssignSequence';
import { useUserLevel, AdminLevel } from "../../admin";

interface SequencesProps {
  users: Array<any>; // Adjust the type according to your user object structure
  uid: string
}

const Sequences: React.FC<SequencesProps> = ({ users, uid }) => {
  const [visible, setVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [editingSequence, setEditingSequence] = useState<any | null>(null);
  const { currentUserLevel, setCurrentUserLevel } = useUserLevel();

  const handleMenuClick = (option: string) => {
    setSelectedOption(option);
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    setSelectedOption(null);
    setEditingSequence(null);
  };

  const handleModify = (productCode: string, sequence: any) => {
    setEditingSequence({ productCode, sequence });
    setSelectedOption('create'); // Switch to the CreateSequence component
    setVisible(true);
  };

  return (
    <div>
      <div className="dropdown">
        <label tabIndex={0} className="btn mx-2 btn-inactive">Sequences</label>
        <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 z-50">
          <li onClick={() => handleMenuClick('create')}>
            <a>Create new sequence</a>
          </li>
          {currentUserLevel === AdminLevel.SUPER_ADMIN && (
            <li onClick={() => handleMenuClick('modify')}>
              <a>Modify sequence</a>
            </li>
          )}
          <li onClick={() => handleMenuClick('assign')}>
            <a>Assign sequence</a>
          </li>
        </ul>
      </div>
  
      {selectedOption === 'create' && (
        <CreateSequence 
          onClose={handleCancel}
          editingSequence={editingSequence}
        />
      )}
      {selectedOption === 'modify' && (
        <ModifySequence 
          onClose={handleCancel}
          onModify={handleModify}
        />
      )}
      {selectedOption === 'assign' && (
        <AssignSequence 
          onClose={handleCancel}
          uid={uid}
          users={users}
        />
      )}
    </div>
  );
};

export default Sequences;
