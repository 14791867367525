import { useEffect, useState, useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { getUserProfile, AuthContext, UserProfile as UP } from "../context/auth";
import { Query, collection, doc, getDoc, getDocs, getDocsFromCache, query, where } from "firebase/firestore";
import { db, getSource } from "../firebase";
import { exportResearchToCsv } from "../utils";

import { md5 } from 'js-md5';
import { DateTime } from "luxon";
import './styles.css'; 
import React from 'react';

import TreatmentGraph from "../components/graphs/TreatmentGraph";
import MetricsGraph from '../components/graphs/MetricsGraph';
import AssessmentGraph from "../components/graphs/AssessmentGraph";
import PainGraph from "../components/graphs/PainGraph";
import Schedule from "../components/schedule/Schedule";

import { useUserLevel, AdminLevel } from "../admin";

export let isGroupDataView = false;

export const setGroupDataView = (value: boolean) => {
  isGroupDataView = value;
};

const useCollectionOnceCache = (query: Query) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [value, setValue] = useState<any>(null);

    const queryString = JSON.stringify({ filters: (query as any)._query.filters, path: (query as any)._query.path });
    const cacheKey = md5(queryString);

    useEffect(() => {
        // Clear the value when the query changes to avoid showing stale data
        setValue(null);
        setLoading(true);
        setError(null);

        const path = (query as any)._query.path.segments.join('/');
        const cachedTime = sessionStorage.getItem(cacheKey);

        const fetchData = async () => {
            try {
                if (cachedTime && (Date.now() - parseInt(cachedTime)) < 60000) { // 1 min refresh
                    console.log(`Fetching ${path} from cache`);
                    const docT = await getDocsFromCache(query);
                    if (docT.empty) {
                        throw new Error("No documents found");
                    }
                    setValue(docT);
                    setError(null);
                } else {
                    console.log(`Fetching ${path} from server`);
                    const docT = await getDocs(query);
                    if (docT.empty) {
                        console.log(`No ${path} found on server`);
                    } else {
                        setValue(docT);
                        setError(null);
                    }
                    sessionStorage.setItem(cacheKey, Date.now().toString());
                }
            } catch (err) {
                if (err instanceof Error && err.message === "No documents found") {
                    console.log(`No ${path} found in cache, fetching from server`);
                    try {
                        const docT = await getDocs(query);
                        if (docT.empty) {
                            console.log(`No ${path} found on server`);
                        }
                        setValue(docT);
                        setError(null);
                    } catch (serverErr) {
                        if (serverErr instanceof Error) {
                            setError(serverErr);
                        } else {
                            setError(new Error("Unknown error occurred"));
                        }
                        setValue(null);
                    }
                } else if (err instanceof Error) {
                    setError(err);
                    setValue(null);
                } else {
                    setError(new Error("Unknown error occurred"));
                    setValue(null);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [queryString]);

    return [value, loading, error];
};

const useMetrics = (id: string | undefined) => {
    const [metrics, setMetrics] = useState<any[]>([]);

    const q = query(
        collection(db, "metrics"),
        where('userId', '==', id),
    );

    const [value, loading, error] = useCollectionOnceCache(q);

    useEffect(() => {
        if (value && !value.empty) {
            const documents = value.docs.map((doc: { data: () => any; }) => doc.data());
            documents.sort((a: { date: string }, b: { date: string }) => {
                const dateA = new Date(a.date.split('/').reverse().join('-'));
                const dateB = new Date(b.date.split('/').reverse().join('-'));
                return dateA.getTime() - dateB.getTime();
            });
            setMetrics(documents);
        } else {
            setMetrics([]);
        }
    }, [value]);

    // Clear metrics data when the id changes
    useEffect(() => {
        setMetrics([]);
    }, [id]);

    return [metrics];
};

const useTreatmentData = (id: string | undefined, startTime: DateTime, endTime: DateTime) => {
    const [treatments, setTreatments] = useState<any[]>([]);

    startTime = startTime.startOf('day');
    endTime = endTime.endOf('day');

    const q = query(
        collection(db, "treatments"),
        where('userId', '==', id),
        where('startTime', '>=', startTime.toJSDate()),
        where('startTime', '<=', endTime.toJSDate())
    );

    const [value, loading, error] = useCollectionOnceCache(q);

    useEffect(() => {
        if (value) {
            let data: any = [];
            for (const doc of value.docs) {
                const d = doc.data();
                data.push({
                    id: doc.id,
                    sequence: d.sequence,
                    startDateOfGroupTreatmentSession: d.startDateOfGroupTreatmentSession ? d.startDateOfGroupTreatmentSession.toDate() : null,
                    groupTreatmentSession: d.groupTreatmentSession,
                    startTime: d.startTime ? DateTime.fromJSDate(d.startTime.toDate()) : null,
                    endTime: d.endTime ? DateTime.fromJSDate(d.endTime.toDate()) : null,
                    mode: d.mode,
                    length: d.length,
                    deviceId: d.deviceId,
                    temperaturePre: d.temperaturePre,
                    temperaturePost: d.temperaturePost,
                    painScorePre: d.painScorePreId,
                    painScorePost: d.painScorePostId,
                    platform: d.platform,
                    deviceTN: d.deviceTN,
                });
            }
            setTreatments(data.sort((a: any, b: any) => a.startTime - b.startTime) as any);
        } else {
            setTreatments([]);
        }
    }, [value]);

    // Clear treatment data when the id changes
    useEffect(() => {
        setTreatments([]);
    }, [id]);

    return [treatments, loading, error];
};

const useBackAssessmentData = (id: string | undefined, startTime: DateTime, endTime: DateTime) => {
    const [backAssessment, setBackAssessment] = useState<any[]>([]);

    startTime = startTime.startOf('day')
    endTime = endTime.endOf('day')

    const [value, loading, error] = useCollectionOnceCache(query(
        collection(db, "back_assessment"),
        where('userId', '==', id),
        where('startTime', '>=', startTime.toJSDate()),
        where('startTime', '<=', endTime.toJSDate())
    ));

    useEffect(() => {
        if (value) {
            let data: any = []
            for (const doc of value.docs) {
                const d = doc.data()
                data.push({
                    startTime: d.startTime ? DateTime.fromJSDate(d.startTime.toDate()) : null,
                    flexibilityScore1: Math.round(d.flexibilityScore1 || 0),
                    flexibilityScore2: Math.round(d.flexibilityScore2 || 0),
                    flexibilityScore3: Math.round(d.flexibilityScore3 || 0),
                    flexibilityScore4: Math.round(d.flexibilityScore4 || 0),
                    flexibilityScore5: Math.round(d.flexibilityScore5 || 0),
                    flexibilityScore6: Math.round(d.flexibilityScore6 || 0),
                    deviceId: d.deviceId,
                    id: doc.id
                })
            }
            setBackAssessment(data.sort((a: any, b: any) => a.startTime - b.startTime) as any)
        }
    }, [value]);

    
    return [backAssessment, loading, error]
}

const useKneeAssessmentData = (id: string | undefined, startTime: DateTime, endTime: DateTime) => {
    const [kneeAssessment, setKneeAssessment] = useState<any[]>([]);

    startTime = startTime.startOf('day')
    endTime = endTime.endOf('day')

    const [value, loading, error] = useCollectionOnceCache(query(
        collection(db, "knee_assessment"),
        where('userId', '==', id),
        where('startTime', '>=', startTime.toJSDate()),
        where('startTime', '<=', endTime.toJSDate())
    ));

    useEffect(() => {
        if (value) {
            let data: any = []
            for (const doc of value.docs) {
                const d = doc.data()
                data.push({
                    startTime: d.startTime ? DateTime.fromJSDate(d.startTime.toDate()) : null,
                    flexibilityScore1: Math.round(d.flexibilityScore1 || 0),
                    deviceId: d.deviceId,

                    id: doc.id
                })
            }
            setKneeAssessment(data.sort((a: any, b: any) => a.startTime - b.startTime) as any)
        }
    }, [value]);

    return [kneeAssessment, loading, error]
}

const useArmAssessmentData = (id: string | undefined, startTime: DateTime, endTime: DateTime) => {
    const [armAssessment, setArmAssessment] = useState<any[]>([]);

    startTime = startTime.startOf('day')
    endTime = endTime.endOf('day')

    const [value, loading, error] = useCollectionOnceCache(query(
        collection(db, "arm_assessment"),
        where('userId', '==', id),
        where('startTime', '>=', startTime.toJSDate()),
        where('startTime', '<=', endTime.toJSDate())
    ));

    useEffect(() => {
        if (value) {
            let data: any = []
            for (const doc of value.docs) {
                const d = doc.data()
                data.push({
                    startTime: d.startTime ? DateTime.fromJSDate(d.startTime.toDate()) : null,
                    flexibilityScore1: Math.round(d.flexibilityScore1 || 0),
                    deviceId: d.deviceId,

                    id: doc.id
                })
            }
            setArmAssessment(data.sort((a: any, b: any) => a.startTime - b.startTime) as any)
        }
    }, [value]);

    return [armAssessment, loading, error]
}
  
const usePainData = (id: string | undefined, startTime: DateTime, endTime: DateTime) => {
    const [pain, setPain] = useState<any[]>([]);

    startTime = startTime.startOf('day');
    endTime = endTime.endOf('day');

    const [value, loading, error] = useCollectionOnceCache(query(
        collection(db, "treatments"),
        where('userId', '==', id),
        where('startTime', '>=', startTime.toJSDate()),
        where('startTime', '<=', endTime.toJSDate())
    ));

    useEffect(() => {
        if (value) {
            let data: any = [];
            for (const doc of value.docs) {
                const d = doc.data();
                const startTime = d.startTime ? DateTime.fromJSDate(d.startTime.toDate()) : null;

                // Create separate data points for painScorePreId and painScorePostId
                if (d.painScorePreId !== undefined) {
                    data.push({
                        startTime,
                        pain: d.painScorePreId,
                        id: `${doc.id}`,
                        pre: true
                    });
                }
                if (d.painScorePostId !== undefined) {
                    data.push({
                        startTime,
                        pain: d.painScorePostId,
                        id: `${doc.id}`,
                        pre: false
                    });
                }
            }
            // Sort by startTime and set the pain state
            setPain(data.sort((a: any, b: any) => a.startTime - b.startTime));
        }
    }, [value]);

    return [pain, loading, error];
};

const useGroupMetrics = (groupId: string | null | undefined) => {
    const [data, setData] = useState<any[]>([]);
    const [ids, setIds] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    //const { currentUser, profile } = useContext(AuthContext);

    // Cache key based on groupId
    const cacheKey = `groupMetrics_${groupId}`;
    const cacheTimestampKey = `groupMetricsTimestamp_${groupId}`;


    useEffect(() => {
        
        const fetchGroupData = async () => {
            try {
                setLoading(true);

                // const userSnap = await getDoc(doc(db, 'users', currentUser!.uid));
                // setGroupId(userSnap.data()?.groupId)
                // if(groupId === null){
                //     return 
                // }

                // Check if the data is cached and if it's within the 10-minute window
                const cachedData = sessionStorage.getItem(cacheKey);
                const cachedTimestamp = sessionStorage.getItem(cacheTimestampKey);
                const now = Date.now();

                // If cached data is within 10 minutes, use it
                if (cachedData && cachedTimestamp && (now - parseInt(cachedTimestamp)) < 600000) { // 10 minutes = 600,000 ms
                    console.log('Using cached data');
                    setData(JSON.parse(cachedData));
                    setLoading(false);
                    return;
                }

                // Otherwise, fetch fresh data from Firebase
                console.log('Fetching fresh data');
                const q = query(
                    collection(db, "groups"),
                    where('groupId', '==', groupId),
                );
                const groupQuerySnapshot = await getDocs(q);

                if (!groupQuerySnapshot.empty) {
                    const groupDoc = groupQuerySnapshot.docs[0];
                    const members = groupDoc.data()?.Members || [];

                    // Retrieve member IDs from the users collection by matching their emails
                    const idList: string[] = [];
                    for (const m of members) {
                        const userQuery = query(
                            collection(db, "users"),
                            where('email', '==', m),
                        );
                        const memberQuerySnapshot = await getDocs(userQuery);
                        if (!memberQuerySnapshot.empty) {
                            idList.push(memberQuerySnapshot.docs[0].id);
                        }
                    }
                    setIds(idList);
                } else {
                    console.log("No group found with the specified groupId");
                }
            } catch (err) {
                console.error('Error fetching group members:', err);
                setError(err as Error);
            } finally {
                setLoading(false);
            }
        };

        fetchGroupData();
    }, [groupId]); // Re-run when groupId changes

    // Fetch the metrics for each member and apply caching
    useEffect(() => {
        const fetchMemberMetrics = async () => {
            try {
                if (ids.length === 0) return;
    
                const allMetrics: any[] = [];
    
                for (const id of ids) {
                    // Query the metrics for each member
                    const q = query(
                        collection(db, "metrics"),
                        where('userId', '==', id),
                    );
                    const metricsSnapshot = await getDocs(q);
    
                    if (!metricsSnapshot.empty) {
                        const memberMetrics = metricsSnapshot.docs.map(doc => doc.data());
                        allMetrics.push(...memberMetrics); // Aggregate the metrics
                    }
                }
    
                // Group the metrics by date and merge data
                const groupedMetrics: any = {};
    
                allMetrics.forEach((metric) => {
                    const date = metric.date; // Assuming date is a string in 'dd/MM/yyyy' format
    
                    if (!groupedMetrics[date]) {
                        groupedMetrics[date] = {
                            date,
                            mobility: { count: 0, total: 0, average: 0, single: 0 },
                            pain: { count: 0, total: 0, average: 0, single: 0 },
                            treatment: { count: 0, total: 0, average: 0, single: 0 }
                        };
                    }
    
                    // Combine mobility
                    groupedMetrics[date].mobility.count += metric.mobility.count || 0;
                    groupedMetrics[date].mobility.total += metric.mobility.average || 0;
                    groupedMetrics[date].mobility.single = metric.mobility.single || 0;
    
                    // Combine pain
                    groupedMetrics[date].pain.count += metric.pain.count || 0;
                    groupedMetrics[date].pain.total += metric.pain.average || 0;
                    groupedMetrics[date].pain.single = metric.pain.single || 0;
    
                    // Combine treatment (summed across group members)
                    groupedMetrics[date].treatment.count += metric.treatment.count || 0;
                    groupedMetrics[date].treatment.total += metric.treatment.total || 0;
                    groupedMetrics[date].treatment.single = metric.treatment.single || 0;
                });
    
                // Calculate final averages and totals
                const finalMetrics = Object.values(groupedMetrics).map((metric: any) => {
                    // Calculate mobility and pain averages
                    const mobilityAverage = metric.mobility.count ? metric.mobility.total / metric.mobility.count : 0;
                    const painAverage = metric.pain.count ? metric.pain.total / metric.pain.count : 0;
    
                    // Apply the formula for recovery
                    const recovery = ((10 - painAverage) + mobilityAverage) / 2;
    
                    return {
                        date: metric.date,
                        mobility: {
                            count: metric.mobility.count,
                            total: metric.mobility.total,
                            average: mobilityAverage,
                            single: metric.mobility.single
                        },
                        pain: {
                            count: metric.pain.count,
                            total: metric.pain.total,
                            average: painAverage,
                            single: metric.pain.single
                        },
                        recovery: recovery, // Computed recovery using the formula
                        treatment: {
                            count: metric.treatment.count,
                            total: metric.treatment.total,
                            average: metric.treatment.count ? metric.treatment.total / metric.treatment.count : 0,
                            single: metric.treatment.single
                        }
                    };
                });
    
                finalMetrics.sort((a: { date: string }, b: { date: string }) => {
                    const dateA = new Date(a.date.split('/').reverse().join('-'));
                    const dateB = new Date(b.date.split('/').reverse().join('-'));
                    return dateA.getTime() - dateB.getTime();
                });
    
                // Store data in cache (sessionStorage)
                sessionStorage.setItem(cacheKey, JSON.stringify(finalMetrics));
                sessionStorage.setItem(cacheTimestampKey, DateTime.now.toString());
    
                setData(finalMetrics); // Set the merged and calculated data
    
            } catch (err) {
                console.error('Error fetching member metrics:', err);
                setError(err as Error);
            }
        };
    
        if (ids.length > 0) {
            fetchMemberMetrics();
        }
    }, [ids]); // Re-run when the list of member IDs changes

    // Clear metrics data when the groupId changes
    useEffect(() => {
        setData([]);
    }, [groupId]);

    return { data, loading, error };
};


const useResearchData = (id: string | null | undefined) => {
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    // Cache key based on groupId
    const cacheKey = `researchData_${id}`;
    const cacheTimestampKey = `researchDataTimestamp_${id}`;

    useEffect(() => {
        const fetchResearchData = async () => {
            try {
                setLoading(true);

                // Check if the data is cached and if it's within the 10-minute window
                const cachedData = sessionStorage.getItem(cacheKey);
                const cachedTimestamp = sessionStorage.getItem(cacheTimestampKey);
                const now = Date.now();
                let Data: any[] = [];

                // If cached data is within 1 minute, use it
                if (cachedData && cachedTimestamp && (now - parseInt(cachedTimestamp)) < 60000) { 
                    console.log('Using cached research data');
                    Data = JSON.parse(cachedData);
                
                    // Check if the cached data is valid (not empty or undefined)
                    if (Data && Data.length > 0) {
                        setData(Data);
                        setLoading(false);
                        return;
                    } else {
                        console.log('Cached data is empty or invalid');
                    }
                }

                // Otherwise, fetch fresh data from Firebase
                console.log('Fetching fresh research data');
                const q = query(
                    collection(db, "research_data"),
                    where('userId', '==', id),
                );
                const researchDocs = await getDocs(q);
 
                if (!researchDocs.empty) {
                    Data = researchDocs.docs.map(doc => doc.data()).map(data => {
                        const { freqArray, freqAverage, freqSingle, powerArray, powerSingle, length, startTime, ...rest } = data;

                        // Convert Firebase Timestamp to a JavaScript Date and then to the desired format
                        const startTimeFormatted = DateTime.fromJSDate(startTime.toDate()).toFormat('dd/MM/yyyy HH:mm');
                        return {
                            freqArray: freqArray,
                            freqAverage: freqAverage,  
                            freqSingle: freqSingle,
                            powerArray: powerArray,
                            powerSingle: powerSingle,  
                            length: length, 
                            startTime: startTimeFormatted,  // Formatted date
                            ...rest            
                        };
                    });

                } else {
                    console.log("No research docs found");
                }

                // Sort the combined data by date
                const sortData = Data.sort((a, b) => {
                    const dateA = DateTime.fromFormat(a.startTime, 'dd/MM/yyyy HH:mm');
                    const dateB = DateTime.fromFormat(b.startTime, 'dd/MM/yyyy HH:mm');
                    return dateB.toMillis() - dateA.toMillis();  // Sort in descending order
                });

                // Cache the sorted data
                sessionStorage.setItem(cacheKey, JSON.stringify(sortData));
                sessionStorage.setItem(cacheTimestampKey, now.toString());


                setData(sortData);
                setLoading(false);

            } catch(e) {
                console.log('Error fetching research data', e);
                setError(e as Error);
                setLoading(false);
            }
        };
        fetchResearchData();
    }, [id]);  // Add 'id' as a dependency to avoid repeated calls

    // // Clear data data when the id changes
    // useEffect(() => {
    //     setData([]);
    // }, [id]);

    useEffect(() => {
        //console.log('Data state updated:', data);
    }, [data]);

    return { data, loading, error };
};

export default function UserProfile() {
    const defaultDateStart = DateTime.now().minus({ days: 30 })
    const defaultDateEnd = DateTime.now()

    let { id } = useParams();
    const [trtTableStart, setTrtTableStart] = useState<DateTime>(defaultDateStart)
    const [trtTableEnd, setTrtTableEnd] = useState<DateTime>(defaultDateEnd)

    const [mobTableStart, setMobTableStart] = useState<DateTime>(defaultDateStart)
    const [mobTableEnd, setMobTableEnd] = useState<DateTime>(defaultDateEnd)

    const [painTableStart, setPainTableStart] = useState<DateTime>(defaultDateStart)
    const [painTableEnd, setPainTableEnd] = useState<DateTime>(defaultDateEnd)

    const [userProfile, setUserProfile] = useState<null | UP>(null)

    const [metricsData, metricLoading, metricError] = useMetrics(id)

    const [treatmentData, trtLoading, trtError] = useTreatmentData(id, trtTableStart, trtTableEnd)

    const [backAssessmentData, mobLoading1, mobError1] = useBackAssessmentData(id, mobTableStart, mobTableEnd)

    const [kneeAssessmentData, mobLoading2, mobError2] = useKneeAssessmentData(id, mobTableStart, mobTableEnd)

    const [armAssessmentData, mobLoading3, mobError3] = useArmAssessmentData(id, mobTableStart, mobTableEnd)

    const [painData, painLoading, painError] = usePainData(id, painTableStart, painTableEnd)

    const { data: groupMetricsData, loading, error } = useGroupMetrics(userProfile?.groupId);

    const {data: researchData} = useResearchData(id);

    useEffect(() => {
        if (id) {
            getUserProfile(id).then((user) => {
                setUserProfile(user)
            })
        }
    }, [id])


    return (
        <div className="h-screen overflow-y-auto px-4 pt-4 space-y-4">
              <div style={{ maxWidth: '400px', margin: '8 auto' }}>
                <div className="collapse bg-white">
                    <input type="radio" name="accordion" />
                    <div className="collapse-title text-xl font-bold">
                        Profile Information
                    </div>
                    <div className="collapse-content">
                        <dl>
                            <dd className="mb-5">{userProfile?.researcher ? 'Researcher' : 'Back Recover User'}</dd>
                            <dt style={{ fontWeight: 'bold' }}>Name</dt>
                            <dd className="mb-5">{userProfile?.name}</dd>
                            <dt style={{ fontWeight: 'bold' }}>Email</dt>
                            <dd className="mb-5">{userProfile?.email}</dd>
                            <dt style={{ fontWeight: 'bold' }}>Occupation</dt>
                            <dd className="mb-5">{userProfile?.occupation}</dd>
                            <dt style={{ fontWeight: 'bold' }}>DOB</dt>
                            <dd className="mb-5">
                                {((userProfile?.birthday as any)?.toDate() as Date)?.toDateString()} (
                                {Math.trunc(
                                    (DateTime.now().toMillis() - (userProfile?.birthday as any)?.toMillis()) / 31536000000
                                )}
                                )
                            </dd>
                            <dt style={{ fontWeight: 'bold' }}>Sex</dt>
                            <dd className="mb-5">{userProfile?.sex}</dd>
                            <dt style={{ fontWeight: 'bold' }}>Group</dt>
                            <dd className="mb-5">{userProfile?.groupId}</dd>
                            <dt className="text-sm text-slate-500 font-bold">Sequences</dt>
                            <dt className="text-xs">1MO: Sequence {userProfile?.userSequences?.VM1MOAABLE}</dt>
                            <dt className="text-xs">4MO: Sequence {userProfile?.userSequences?.VM4MOAABLE}</dt>
                            <dt className="text-xs">6MO: Sequence {userProfile?.userSequences?.VM6MOAABLE}</dt>
                        </dl>
                    </div>
                </div>
            </div>
            <div className="collapse bg-white">
                <input type="radio" name="accordion" />
                <div className="collapse-title text-xl font-bold">
                    Metrics
                </div>
                <div className="collapse-content">
                    <MetricsGraph
                        userData={metricsData}
                        groupData={groupMetricsData}
                        userName={userProfile?.name}
                        groupId={userProfile?.groupId}
                        userEmail={userProfile?.email}
                    />
                </div>
            </div>
    
            <div className="collapse bg-white">
                <input type="radio" name="accordion" />
                <div className="collapse-title text-xl font-bold">
                    Schedule
                </div>
                <div className="collapse-content">
                    <Schedule 
                    userId={userProfile?.id}
                    //recoveryPlan={userProfile?.recoveryPlan}

                    />
                </div>
            </div>

            <div className="collapse bg-white">
                <input type="radio" name="accordion" />
                <div className="collapse-title text-xl font-bold">
                    Treatments
                </div>
                <div className="collapse-content">
                    <TreatmentGraph data={treatmentData} userEmail={userProfile?.email} />
                </div>
            </div>
    
            <div className="collapse bg-white">
                <input type="radio" name="accordion" />
                <div className="collapse-title text-xl font-bold">
                    Assessments
                </div>
                <div className="collapse-content">
                    <AssessmentGraph
                        backData={backAssessmentData}
                        kneeData={kneeAssessmentData}
                        armData={armAssessmentData}
                        userEmail={userProfile?.email}
                    />
                </div>
            </div>
    
            <div className="collapse bg-white">
                <input type="radio" name="accordion" />
                <div className="collapse-title text-xl font-bold">
                    Pain
                </div>
                <div className="collapse-content">
                    <PainGraph data={painData} />
                </div>
            </div>
    
            <div className="collapse bg-white">
                <input type="radio" name="accordion" />
                <div className="collapse-title text-xl font-bold">
                    Research Data
                </div>
                <div className="collapse-content">
                    <span
                        className="text-blue-500 cursor-pointer underline"
                        onClick={() => {
                            userProfile?.email !== null &&
                                exportResearchToCsv("research.csv", researchData as any[], userProfile?.email!);
                        }}
                    >
                        Export to CSV
                    </span>
                    <div className="max-h-96 overflow-y-auto mt-4">
                        <table className="table table-zebra w-full text-sm">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Duration (minutes)</th>
                                    <th>Frequency Target</th>
                                    <th>Frequency Average</th>
                                    <th>Power</th>
                                </tr>
                            </thead>
                            <tbody>
                                {researchData.map((row) => (
                                    <tr key={row.id}>
                                        <td>{row.startTime}</td>
                                        <td>{row.length}:00</td>
                                        <td>{row.freqSingle ? `${row.freqSingle} Hz` : '-'}</td>
                                        <td>{row.freqAverage ? `${row.freqAverage} Hz` : '-'}</td>
                                        <td>{row.powerSingle ? `${row.powerSingle}%` : '-'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    );
}
