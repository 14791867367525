import React, { useState } from "react";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

interface CreatePlanProps {
  onClose: () => void;
}

const MAX_WEEKS = 12;
const MAX_ITEMS_PER_DAY = 10;

const CreatePlan: React.FC<CreatePlanProps> = ({ onClose }) => {
  const [recoveryPlan, setRecoveryPlan] = useState<string>("");
  const [weeks, setWeeks] = useState<Array<Record<string, string[]>>>([
    {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    },
  ]);
  const [error, setError] = useState<string | null>(null);

  const handleAddItem = (weekIndex: number, day: string, item: string) => {
    setWeeks((prevWeeks) =>
      prevWeeks.map((week, idx) => {
        if (idx === weekIndex) {
          const updatedDay = [...week[day], item];
          return {
            ...week,
            [day]: updatedDay,
          };
        }
        return week;
      })
    );
  };

  const handleRemoveItem = (weekIndex: number, day: string, itemIndex: number) => {
    setWeeks((prevWeeks) =>
      prevWeeks.map((week, idx) =>
        idx === weekIndex
          ? {
              ...week,
              [day]: week[day].filter((_, idx) => idx !== itemIndex),
            }
          : week
      )
    );
  };

  const handleRemoveWeek = (weekIndex: number) => {
    setWeeks((prevWeeks) => prevWeeks.filter((_, idx) => idx !== weekIndex));
  };

  const handleAddWeek = () => {
    if (weeks.length < MAX_WEEKS) {
      setWeeks((prev) => [
        ...prev,
        { mon: [], tue: [], wed: [], thu: [], fri: [], sat: [], sun: [] },
      ]);
    } else {
      setError("Maximum of 12 weeks reached.");
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    if (!recoveryPlan) {
      setError("Recovery Plan is required.");
      return;
    }
  
    const planRef = doc(db, "recoveryPlans", recoveryPlan);
  
    try {
      // Check if the recovery plan already exists
      const existingPlan = await getDoc(planRef);
      if (existingPlan.exists()) {
        setError(`Recovery Plan "${recoveryPlan}" already exists.`);
        return;
      }
  
      // Transform weeks into the required map structure with unique item keys
      const transformedWeeks = weeks.reduce((acc, week, weekIndex) => {
        const weekKey = `week${weekIndex + 1}`;
        const weekData = Object.entries(week).reduce((dayAcc, [day, items]) => {
          const dayData = items.reduce((itemAcc, item, itemIndex) => {
            // Create a unique item key using the week index, day, and item index
            const itemKey = `${weekIndex + 1}-${day}-${itemIndex}-${item}`;
            itemAcc[itemKey] = false; // Initialize all items with `false`
            return itemAcc;
          }, {} as Record<string, boolean>);
  
          dayAcc[day] = dayData;
          return dayAcc;
        }, {} as Record<string, Record<string, boolean>>);
  
        acc[weekKey] = weekData;
        return acc;
      }, {} as Record<string, Record<string, Record<string, boolean>>>);
  
      // Include only non-null values
      const planData = {
        recoveryPlan,
        userId: null,
        startDate: null,
        ...transformedWeeks,
      };
  
      const filteredPlanData = Object.fromEntries(
        Object.entries(planData).filter(([_, value]) => value !== null)
      );
  
      // Save the new recovery plan
      await setDoc(planRef, filteredPlanData);
      console.log("Plan created:", filteredPlanData);
      onClose();
    } catch (err) {
      console.error("Error creating plan:", err);
      setError("Failed to create recovery plan. Please try again.");
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white h-5/6 w-11/12 lg:w-3/4 xl:w-5/6 p-8 rounded-3xl shadow-2xl relative overflow-auto">
        <button
          className="btn btn-sm btn-circle absolute right-2 top-2"
          onClick={onClose}
        >
          ✕
        </button>

        <h2 className="text-3xl font-semibold text-gray-800 mb-6">
          Create Recovery Plan
        </h2>

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Recovery Plan Name */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Recovery Plan Name
            </label>
            <input
              type="text"
              value={recoveryPlan}
              onChange={(e) => setRecoveryPlan(e.target.value)}
              className="input input-bordered w-full"
              required
            />
          </div>

          {/* Weeks Table */}
          {weeks.map((week, weekIndex) => (
            <div key={weekIndex} className="mb-8">
              <div className="flex justify-between items-center">
                <h3 className="text-xl font-semibold text-gray-800 mb-4">
                  Week {weekIndex + 1}
                </h3>
                {weekIndex !== 0 && (
                  <button
                    type="button"
                    className="btn btn-error btn-sm"
                    onClick={() => handleRemoveWeek(weekIndex)}
                  >
                    Remove Week
                  </button>
                )}
              </div>
              <table className="table w-full border border-gray-300">
                <thead>
                  <tr>
                    {Object.keys(week).map((day) => (
                      <th
                        key={day}
                        className="text-center border border-gray-300"
                      >
                        {day.charAt(0).toUpperCase() + day.slice(1)}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {Object.entries(week).map(([day, items]) => (
                      <td
                        key={day}
                        className="p-4 align-top border border-gray-300"
                      >
                        <ul className="space-y-2">
                          {items.map((item, idx) => (
                            <li
                              key={idx}
                              onClick={() => handleRemoveItem(weekIndex, day, idx)}
                              style={{
                                borderColor: item === "Treatment" ? "#9747ff" : "#56c1ca",
                                color: item === "Treatment" ? "#9747ff" : "#56c1ca",
                              }}
                              className={`px-2 py-1 text-sm rounded-full text-center cursor-pointer ${
                                item === "Treatment"
                                  ? "border border-9747ff text-9747ff"
                                  : "border border-56c1ca text-56c1ca"
                              }`}
                            >
                              {item}
                            </li>
                          ))}
                        </ul>
                        {items.length < MAX_ITEMS_PER_DAY && (
                          <div className="space-y-2 mt-2">
                            <button
                              type="button"
                              className="btn btn-s"
                              onClick={() =>
                                handleAddItem(weekIndex, day, "Treatment")
                              }
                            >
                              + Treatment
                            </button>
                            <button
                              type="button"
                              className="btn btn-s"
                              onClick={() =>
                                handleAddItem(weekIndex, day, "Back Assessment")
                              }
                            >
                              + Back Assessment
                            </button>
                            <button
                              type="button"
                              className="btn btn-s"
                              onClick={() =>
                                handleAddItem(weekIndex, day, "Knee Assessment")
                              }
                            >
                              + Knee Assessment
                            </button>
                            <button
                              type="button"
                              className="btn btn-s"
                              onClick={() =>
                                handleAddItem(weekIndex, day, "Arm Assessment")
                              }
                            >
                              + Arm Assessment
                            </button>
                          </div>
                        )}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          ))}

          {/* Add Week Button */}
          {weeks.length < MAX_WEEKS && (
            <button
              type="button"
              className="btn btn-outline btn-primary w-full"
              onClick={handleAddWeek}
            >
              + Add Another Week
            </button>
          )}

          {/* Error Message */}
          {error && <p className="text-red-500">{error}</p>}

          {/* Submit Button */}
          <button type="submit" className="btn btn-primary w-full">
            Create Plan
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreatePlan;